















import { Component, Vue } from "vue-property-decorator";

@Component
export default class CannotAccess extends Vue {
  public redirectToHome() {
    this.$router.push("/activitylog");
  }
}
